html,body, #root{
  width: 100%;
  height: 100%;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  /* background-color: #faebd7; */
  height: 100%;
}

.header { 
  position: fixed;
  width: 100%;
  height: 90px;
  background: rgba(255,255,255,0.8);
  /* z-index: 999; */
}

.bg {
  width:100%;
  min-height: 70vw;
  background-image: url('./assets/hersalon_1.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
}

.title {
  color: #fffaf5;
  color: black;
  font-size: 42px;
  padding-top: 10px;
  font-weight: 900;
  z-index: 2;
  position: absolute;
  width: 100%;
}

.subtitle {
  color: rgb(255, 250, 245);
  color: black;
  font-size: 18px;
  font-weight: 100;
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 60px;
}

.topper {
  background: linear-gradient(0deg, rgba(250,235,215,0) 0%, rgba(255,255,255,0) 76%, rgba(198,178,164,0.6012998949579832) 100%); 
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

.signup {
  /* height: 500px; */
  width:100%;
  background: white;
  max-width: 1140px;
  margin: auto;
}

h1 {
  font-size: 44px;
  color:rgba(63, 77, 84, 1);
  margin-top: 60px;
  margin-bottom: 20px;
}

h5 {
  font-size: 16px;
  font-weight: 200;
  color:black; 
  /* rgba(96, 121, 169, 0.601); */
  margin: 0;
  margin-bottom: 10px;
}
.middle {
  background: rgba(63, 77, 84, 1);
  color:white;
  opacity: 0.8;
}
h2 {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 18px;
}
h6 {
  font-weight: 100;
  font-size: 14px;
  margin: 0 0 10px 0;
}
.actions {
  display: flex;
  padding-top: 20px;
  padding-bottom: 50px;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.whatsapp {
  width: 66px;
  height: 66px;
  background-image: url('./assets/WhatsApp_icon.png');
  background-size: contain;
}

.email {
  width: 50px;
  height: 50px;
  background-image: url('./assets/email_icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.group {
  width: 60px;
  height: 60px;
  background-image: url('./assets/group.png');
  background-size: contain;
}